import { Input } from "@reframe.so/blocks";
import { useUpload } from "@src/hooks/upload";
import { useRef, useState } from "react";

export const Upload: React.ComponentType<{
  prefix: string;
  fileTypes: string[];
  onUpload: (url: string) => Promise<void> | void;
  multiple?: boolean;
  render: (props: {
    uploading: boolean;
    unsupported: boolean;
    onClick: () => void;
  }) => React.ReactNode;
}> = ({ prefix, fileTypes, onUpload, multiple, render }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [unsupported, setUnsupported] = useState(false);

  const { uploading, uploadToClient } = useUpload({
    prefix,
    fileTypes,
    onUnsupportedFile: () => setUnsupported(true),
    onUpload,
  });

  return (
    <>
      {render({
        uploading,
        unsupported,
        onClick: () => {
          if (ref.current) {
            ref.current.click();
          }
        },
      })}

      <Input
        ref={ref}
        type="file"
        accept={fileTypes.join(",")}
        css="hidden"
        multiple={multiple}
        onChange={async (e) => {
          setUnsupported(false);
          await uploadToClient(e.target.files);
          if (ref.current) {
            ref.current.value = "";
          }
        }}
      />
    </>
  );
};
