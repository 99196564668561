"use client";

import { Button, CSS } from "@reframe.so/blocks";
import { CheckIcon } from "@reframe.so/icons/icons/CheckIcon";
import { CopyIcon } from "@reframe.so/icons/icons/CopyIcon";
import { useCallback, useState } from "react";

export const useCopyToClipboard = (getText: () => string) => {
  const [copied, setCopied] = useState(false);

  const copy = useCallback(() => {
    navigator.clipboard.writeText(getText());

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, [getText]);

  return {
    copied,
    copy,
  };
};

export const CopyButton: React.ComponentType<{
  title?: React.ReactNode;
  css?: CSS;
  getText: (() => string) | string;
}> = ({ title, getText, css }) => {
  const { copied, copy } = useCopyToClipboard(
    typeof getText === "string" ? () => getText : getText
  );

  return (
    <Button
      variant="link"
      onClick={() => copy()}
      css={["text-neutral-50", css]}
    >
      {!copied ? <CopyIcon size={20} /> : <CheckIcon size={20} />}
      {title ? typeof title === "string" ? <>&nbsp;{title}</> : title : null}
    </Button>
  );
};
