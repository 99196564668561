"use client";

import { Y, Text, Button, X } from "@reframe.so/blocks";

export const Section: React.ComponentType<{
  loading?: boolean;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
  primaryAction?: {
    label: string;
    onClick: () => void;
  };
  secondaryAction?: {
    label: string;
    onClick: () => void;
  };
}> = ({
  loading,
  title,
  subtitle,
  primaryAction,
  secondaryAction,
  children,
}) => {
  return (
    <X
      css="wrap gap-8 border-b last:border-b-0 border-b-neutral-400/20 p-6"
      data-component="section"
    >
      <Y css="basis-1/4 grow shrink-0 gap-1">
        <Text css="text-neutral-600 font-medium">{title}</Text>
        <Text css="text-xs text-neutral-500">{subtitle}</Text>
      </Y>
      <Y css="basis-full md:basis-2/4 grow gap-8">
        {children}

        <X css="gap-2">
          {!primaryAction ? null : (
            <Button onClick={() => primaryAction.onClick()} loading={loading}>
              {primaryAction.label}
            </Button>
          )}
          {!secondaryAction ? null : (
            <Button
              variant="secondary"
              onClick={() => secondaryAction.onClick()}
              loading={loading}
            >
              {secondaryAction.label}
            </Button>
          )}
        </X>
      </Y>
    </X>
  );
};
